import http from "@/utils/axios";
import { notify } from "@kyvg/vue3-notification";

const notifyConf = {
  duration: 5000,
};

const cleanNotify = () => {
  notify({
    clean: true,
  });
};

export default {
  namespaced: true,
  state: {
    currentUser: null,
    address: null,
    addresses: [],
    childrens: [],
    doctors: [],
    facilities: [],
    billingData: null,
    invoicesData: [],
    errors: [],
    stats: {
      referrals: {
        issued: 0,
        paid: 0,
        canceled: 0,
        completed: 0,
      },
      commission: 0,
    },
    toPay: 0,
    showConsentExaminationsDoctorsModal: false,
    consents: {
      accept_processing: false,
      accept_marketing: false,
    },
  },
  getters: {
    getCurrentUser: (state) => state.currentUser,
    getAddress: (state) => state.address,
    getAddresses: (state) => state.addresses,
    getAddressesForHomeService: (state, getters, rootState, rootGetters) => {
      const cities = rootGetters["cart/getAllCities"].map((city) => city.name);
      return state.addresses.filter((address) => cities.includes(address.city));
    },
    hasAddresses: (state) => state.addresses.length > 0,
    getChildrens: (state) => state.childrens,
    getDoctors: (state) => state.doctors,
    hasChildrens: (state) => state.childrens.length > 0,
    hasDoctors: (state) => state.doctors.length > 0,
    getFacilities: (state) => state.facilities,
    hasFacilities: (state) => state.facilities.length > 0,
    getInvoicesData: (state) => state.invoicesData,
    getCurrentUserStats: (state) => state.stats,
    getCurrentUserRole: (state) => state.currentUser.role,
    getCurrentUserShouldAcceptTerms: (state) =>
      state.currentUser.should_accept_terms,
    getCurrentUserShouldResetPassword: (state) =>
      state.currentUser.should_reset_password,
    getCurrentUserVerified: (state) => state.currentUser.sms_verified,
    getCurrentUserFacility: (state) => state.currentUser.has_selected_facility,
    getCurrentUserStatus: (state) => state.currentUser.status,
    getCurrentUserBillingData: (state) => state.billingData,
    getErrors: (state) => {
      return state.errors;
    },
    getToPay: (state) => state.toPay,
    getHasBillingData: (state) => state.currentUser.has_billing_data,
    getConsentExaminationsDoctorsModal: (state) =>
      state.showConsentExaminationsDoctorsModal,
    getConsents: (state) => state.consents,
  },
  mutations: {
    setCurrentUser(state, user) {
      state.currentUser = user;
    },
    updateCurrentUser(state, { key, value }) {
      state.currentUser[key] = value;
    },
    setAddress(state, address) {
      state.address = address;
    },
    setAddresses(state, addresses) {
      state.addresses = addresses;
    },
    pushAddress(state, address) {
      state.addresses = [...state.addresses, address];
    },
    removeAddresses(state, id) {
      state.addresses = state.addresses.filter((address) => address.id != id);
    },
    setChildrens(state, childrens) {
      state.childrens = childrens;
    },
    pushChildren(state, children) {
      state.childrens = [...state.childrens, children];
    },
    removeChildren(state, id) {
      const children = state.childrens.filter((data) => data.id !== id);
      state.childrens = children;
    },
    setDoctors(state, doctors) {
      state.doctors = doctors;
    },
    setFacilities(state, facilities) {
      state.facilities = facilities;
    },
    setDefaultFacilitie(state, id) {
      state.facilities = state.facilities.map((facility) => {
        return { ...facility, is_default: facility.id === id };
      });
    },
    pushFacility(state, facility) {
      state.facilities = [...state.facilities, facility];
    },
    removeCollectionPoint(state, id) {
      state.facilities = state.facilities.filter(
        (facility) => facility.id != id
      );
    },
    setInvoicesData(state, invoicesData) {
      state.invoicesData = invoicesData;
    },
    pushInvoiceData(state, invoiceData) {
      state.invoicesData = [...state.invoicesData, invoiceData];
    },
    updateInvoiceData(state, invoiceData) {
      const index = state.invoicesData.findIndex(
        ({ id }) => invoiceData.id === id
      );

      state.invoicesData[index] = {
        ...state.invoicesData[index],
        ...invoiceData,
      };
    },
    removeInvoiceData(state, id) {
      state.invoicesData = state.invoicesData.filter((data) => data.id != id);
    },
    setUserBillingData(state, billingData) {
      state.billingData = billingData;
    },
    setCurrentUserStats(state, stats) {
      state.stats = stats;
    },
    setUserStats(state, stats) {
      state.stats = stats;
    },
    setUserToPay(state, toPay) {
      state.toPay = toPay;
    },
    resetCurrentUser(state) {
      state.currentUser = null;
      state.billingData = null;
    },
    setErrors(state, errors) {
      state.errors = errors;
    },
    setShowConsentExaminationsDoctorsModal(state, show) {
      state.showConsentExaminationsDoctorsModal = show;
    },
    setShowConsentExaminationsDoctorsModalFalse(state) {
      state.showConsentExaminationsDoctorsModal = false;
    },
    clearErrors(state) {
      state.errors = [];
      cleanNotify();
    },
    setConsents(state, consents) {
      state.consents = consents;
    },
  },
  actions: {
    async storeAddress({ commit }, payload) {
      try {
        commit("clearErrors");
        const { data } = await http.post("/profile/address", payload);
        commit("pushAddress", data.address);

        notify({
          type: "success",
          text: "messages.save.success",
          ...notifyConf,
        });
      } catch ({ response }) {
        if (response && response.status === 422) {
          commit("setErrors", response.data.errors);
        }

        notify({
          type: "danger",
          text: "messages.save.failure",
          ...notifyConf,
        });

        throw new Error();
      }
    },
    async storeChild({ commit }, payload) {
      try {
        commit("clearErrors");
        const { data } = await http.post("/family/children", payload);
        commit("pushChildren", data);

        notify({
          type: "success",
          text: "messages.save.success",
          ...notifyConf,
        });
      } catch ({ response }) {
        if (response && response.status === 422) {
          commit("setErrors", response.data.errors);
        }

        notify({
          type: "danger",
          text: "messages.save.failure",
          ...notifyConf,
        });
        throw new Error();
      }
    },
    async removeChild({ commit }, id) {
      try {
        commit("clearErrors");
        const { data } = await http.delete(`/family/children/${id}`);
        commit("removeChildren", id);

        notify({
          type: "success",
          text: "messages.delete.success",
          ...notifyConf,
        });
      } catch ({ response }) {
        if (response && response.status === 422) {
          commit("setErrors", response.data.errors);
        }

        notify({
          type: "danger",
          text: "messages.delete.failure",
          ...notifyConf,
        });

        throw new Error();
      }
    },
    async updateAddress({ commit }, { id, data }) {
      commit("clearErrors");

      try {
        await http.put(`/profile/address/${id}`, data);

        notify({
          type: "success",
          text: "messages.edit.success",
          ...notifyConf,
        });
      } catch ({ response }) {
        if (response && response.status === 422)
          commit("setErrors", response.data.errors);

        notify({
          type: "danger",
          text: "messages.edit.failure",
          ...notifyConf,
        });

        throw new Error();
      }
    },
    async deleteAddress({ commit }, id) {
      try {
        await http.delete(`/profile/address/${id}`);
        commit("removeAddresses", id);

        notify({
          type: "success",
          text: "messages.delete.success",
          ...notifyConf,
        });
      } catch (error) {
        notify({
          type: "success",
          text: "messages.delete.failure",
          ...notifyConf,
        });
      }
    },
    async storeCollectionPoint({ commit }, id) {
      try {
        commit("clearErrors");
        const { data } = await http.post("/profile/collection-point", { id });
        commit("pushFacility", data.data);

        notify({
          type: "success",
          text: "messages.save.success",
          ...notifyConf,
        });
      } catch ({ response }) {
        if (response && response.status === 422) {
          commit("setErrors", response.data.errors);
        }

        notify({
          type: "danger",
          text: "messages.save.failure",
          ...notifyConf,
        });

        throw new Error();
      }
    },
    async storeDefaultCollectionPoint({ commit }, id) {
      try {
        commit("clearErrors");
        await http.post("/profile/collection-point/default", {
          id,
        });

        commit("setDefaultFacilitie", id);

        notify({
          type: "success",
          text: "messages.save.success",
          ...notifyConf,
        });
      } catch ({ response }) {
        if (response && response.status === 422) {
          commit("setErrors", response.data.errors);
        }

        notify({
          type: "danger",
          text: "messages.save.failure",
          ...notifyConf,
        });

        throw new Error();
      }
    },
    async shareExaminationsForDoctor({ commit }, { id, share }) {
      try {
        commit("clearErrors");
        await http.put(`/profile/share-examination/${id}`, {
          share: share,
        });

        notify({
          type: "success",
          text: "messages.save.success",
          ...notifyConf,
        });
      } catch ({ response }) {
        if (response && response.status === 422) {
          commit("setErrors", response.data.errors);
        }

        notify({
          type: "danger",
          text: "messages.save.failure",
          ...notifyConf,
        });

        throw new Error();
      }
    },
    async deleteCollectionPoint({ commit }, id) {
      try {
        await http.delete(`/profile/collection-point/${id}`);
        commit("removeCollectionPoint", id);

        notify({
          type: "success",
          text: "messages.delete.success",
          ...notifyConf,
        });
      } catch (error) {
        notify({
          type: "success",
          text: "messages.delete.failure",
          ...notifyConf,
        });
      }
    },
    async storeInvoiceData({ commit }, payload) {
      try {
        commit("clearErrors");
        const { data } = await http.post("/profile/invoice-data", payload);
        commit("pushInvoiceData", data.data);

        notify({
          type: "success",
          text: "messages.save.success",
          ...notifyConf,
        });
      } catch ({ response }) {
        if (response && response.status === 422) {
          commit("setErrors", response.data.errors);
        }

        notify({
          type: "danger",
          text: "messages.save.failure",
          ...notifyConf,
        });

        throw new Error();
      }
    },
    async updateInvoiceData({ commit }, { id, data }) {
      commit("clearErrors");

      try {
        const { data: resData } = await http.put(
          `/profile/invoice-data/${id}`,
          data
        );

        commit("updateInvoiceData", resData.data);

        notify({
          type: "success",
          text: "messages.edit.success",
          ...notifyConf,
        });
      } catch ({ response }) {
        if (response && response.status === 422)
          commit("setErrors", response.data.errors);

        notify({
          type: "danger",
          text: "messages.edit.failure",
          ...notifyConf,
        });

        throw new Error();
      }
    },
    async deleteInvoiceData({ commit }, id) {
      try {
        await http.delete(`/profile/invoice-data/${id}`);
        commit("removeInvoiceData", id);

        notify({
          type: "success",
          text: "messages.delete.success",
          ...notifyConf,
        });
      } catch (error) {
        notify({
          type: "success",
          text: "messages.delete.failure",
          ...notifyConf,
        });
      }
    },
    async fetchAccountData({ commit, getters }) {
      const { data } = await http.get("/account-data");
      commit("setAddresses", data.addresses);
      commit("setChildrens", data.childrens);
      commit("setDoctors", data.doctors);
      commit("setFacilities", data.facilities);
      commit("setInvoicesData", data.invoicesData);
      commit("setAddress", getDefaultOrFirst(data.addresses));
      if (
        this.state.user.doctors.length > 0 &&
        this.state.user.doctors.some(
          (doc) => doc.ask_for_examinations === true
        ) &&
        !getters["showConsentModal"]
      ) {
        commit("setShowConsentExaminationsDoctorsModal", true);
        commit("modals/openModal", "consentDoctorsModal", { root: true });
      }
    },
    async fetchCurrentUser({ commit, dispatch, rootGetters }) {
      const response = await http.get("/user");
      const user = response.data.user;

      commit("setCurrentUser", user);

      commit("billingData/setCheckAll", false, { root: true });

      if (user.billing_data) {
        commit("setUserBillingData", user.billing_data);
        commit("billingData/setBillingData", user.billing_data, { root: true });
      }
      if (user.role === "doctor") {
        commit("setUserStats", response.data.stats);
        commit(
          "cart/setPatientDiscountPercent",
          user.patient_discount_percent,
          { root: true }
        );
      }

      dispatch("checkout/createOrUpdatePatient", user, {
        root: true,
      });

      await dispatch("fetchAccountData");

      if (!rootGetters["checkout/getAddressData"].city)
        dispatch("checkout/setUserDefaultData", null, {
          root: true,
        });

      if (!rootGetters["cart/getSelectedFacility"])
        dispatch("cart/setUserDefaultFacility", user.default_facility, {
          root: true,
        });
    },
    async updateCurrentUser({ commit, dispatch }, user) {
      return http
        .post(`/profile/update`, user)
        .then((response) => {
          const user = response.data.user;
          commit("setCurrentUser", user);
          dispatch(
            "checkout/updatePatientById",
            { id: user.id, patient: user },
            { root: true }
          );
          commit("clearErrors");
          notify({
            type: "success",
            text: "messages.edit.success",
            duration: 5000,
          });
        })
        .catch(({ response }) => {
          if (response && response.status === 422) {
            commit("setErrors", response.data.errors);
          } else if (response && response.status === 403) {
            if (response.data.message == "error.current_password.incorrect") {
              commit("setErrors", {
                current_password: [response.data.message],
              });
            } else if (response.data.message == "error.code.incorrect") {
              commit("setErrors", {
                code: [response.data.message],
              });
            }
          }

          notify({
            type: "danger",
            text: "messages.edit.failure",
            ...notifyConf,
          });

          throw new Error();
        });
    },
    async fetchUserStats(
      { commit },
      { time = "current_month", date_range = null }
    ) {
      await http
        .get("/doctor/stats", {
          params: {
            time: time,
            date_range: date_range,
          },
        })
        .then(({ data }) => {
          commit("setCurrentUserStats", data.stats);
        });
    },
    async isRegisterUser(_, payload) {
      return await http.post("/check/user/exists", payload);
    },
    async fetchConsents({ commit }) {
      const { data } = await http.get("/userConsent/actualConsent");

      commit("setConsents", {
        accept_marketing: data.accept_marketing,
        accept_processing: data.accept_processing,
      });
    },
    async updateConsent({ commit }, consent) {
      const { data } = await http.post("/userConsent/changeConsent", consent);

      notify({
        type: "success",
        text: "messages.save.success",
        ...notifyConf,
      });

      commit("setConsents", {
        accept_marketing: data.accept_marketing,
        accept_processing: data.accept_processing,
      });
    },
  },
};

const getDefaultOrFirst = (addresses) => {
  const defaultAddress = addresses.find((address) => address.is_default);

  return defaultAddress || addresses[0] || null;
};
